import axios from "axios";

export const state = {
    myshares: []
};

export const getters = {
    myshares: state => state.myshares
};

export const mutations = {
    setMyShares(state, newValue) {
        state.myshares = newValue
    },
};

export const actions = {
    fetchMyShares({ commit }) {
        axios.get('/myshares').then(res => {
            commit('setMyShares', res.data.shares)
        })
    },
};
