<template>
  <div class="relative-position">
    <div class="p-3 d-md-block d-none">
      <div>Loans</div>
      <div class="card shadow my-4">
        <div class="text-center pt-3">
          <button @click.prevent="loan_type = 'applications'" class="mx-4 btn"
            :class="{ 'btn-default': loan_type == 'applications' }">Loan Applications</button>
          <button @click.prevent="loan_type = 'disbursements'" class="mx-4 btn"
            :class="{ 'btn-default': loan_type == 'disbursements' }">Approved Applications</button>
          <button @click.prevent="loan_type = 'running'" class="mx-4 btn"
            :class="{ 'btn-default': loan_type == 'running' }">Running Loans</button>
          <button @click.prevent="loan_type = 'closed'" class="mx-4 btn"
            :class="{ 'btn-default': loan_type == 'closed' }">Closed Loans</button>
        </div>
        <div class="card-body scrollable p-0">
          <div class="d-flex align-items-center p-3">
            <h4 class="mt-3 mb-4">Loans</h4>
            <select v-model="items" id="transactions" placeholder="Show" class="ml-2 form-control form-control-sm"
              style="width: 60px;">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="80">80</option>
            </select>
          </div>
          <div class="mx-4">
            <div class="row px-3 align-items-center" style="font-size: 15px;">
              <div class="col-1"></div>
              <div class="col"><b>Loan</b></div>
              <div class="col" v-if="loan_type == 'applications' || loan_type == 'disbursements'"><b>Amount</b></div>
              <div class="col" v-if="!(loan_type == 'closed')"><b>Payback</b></div>
              <div class="col" v-if="loan_type == 'applications' || loan_type == 'disbursements'"><b>You Get</b></div>
              <div class="col"><b>Payment Plan</b></div>
              <div class="col" v-if="loan_type == 'running'"><b>Fine</b></div>
              <div class="col" v-if="loan_type == 'running'"><b>Balance</b></div>
              <div class="col" v-if="loan_type == 'closed'"><b>Paid</b></div>

              <div v-if="loan_type == 'applications'" class="col"><b>Application Date</b></div>
              <div v-if="loan_type == 'disbursements'" class="col"><b>Approved Date</b></div>
              <div v-if="loan_type == 'running'" class="col"><b>Expiry Date</b></div>
              <div v-if="loan_type == 'closed'" class="col"><b>Closed Date</b></div>

            </div>
            <div v-if="(loans[loan_type]).length > 0">
              <div class="row border rounded p-3 my-2 align-items-center" v-for="(loan, index) in loans[loan_type]"
                :key="index" v-if="index + 1 <= items">
                <div class="col-1"><i class="fa fa-bank"></i></div>
                <div class="col">{{ loan.loan_type.name }} <small class="d-block text-primary">{{ loan.loan_type.period
                    }}
                    days</small></div>
                <div class="col" v-if="loan_type == 'applications' || loan_type == 'disbursements'">{{
                  commas(loan.amount) }}/=</div>
                <div class="col" v-if="!(loan_type == 'closed')">{{ commas(Number(loan.amount) + Number(loan.interest))
                  }}/=</div>
                <div class="col" v-if="loan_type == 'applications' || loan_type == 'disbursements'">{{
                  commas(Number(loan.amount) - Number(loan.charges)) }}/=</div>
                <div class="col">{{ commas(loan.repaymentplan) }}/= <small class="d-block text-primary">Per {{
                  loan.repaymentstructure }}</small></div>
                <div class="col" v-if="loan_type == 'running'">{{ commas(loan.fine) }}/=</div>
                <div class="col" v-if="loan_type == 'running'">{{ commas(Number(loan.amount) + Number(loan.fine) +
                  Number(loan.interest) -
                  Number(loan.paid)) }}/=</div>
                <div class="col" v-if="loan_type == 'closed'">{{ commas(loan.paid) }}/=</div>

                <div v-if="loan_type == 'applications'" class="col">{{ loan.applicationdate }}</div>
                <div v-if="loan_type == 'disbursements'" class="col">{{ loan.disbursement_date }}</div>
                <div v-if="loan_type == 'running'" class="col">{{ loan.expirydate }} <small
                    class="d-block text-danger">{{ loan.daysleft }} days left</small></div>
                <div v-if="loan_type == 'closed'" class="col">{{ loan.closed_date }}</div>

              </div>
            </div>
            <div v-else>
              <div class="text-primary border rounded p-3 my-2 text-center">
                <img src="@/assets/images/no-data-found.png" alt="NO DATA FOUND" style="height: 200px;">
                <div class="text-default" style="font-size: 20px; margin-top: -40px;"><b>NO DATA FOUND</b></div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <!-- Phone -->
    <div class="p-3 d-block d-md-none">
      <div>Loans</div>
      <div class="card shadow my-4">
        <div class="text-center pt-3">
          <button @click.prevent="loan_type = 'applications'" class="mx-2 round-button btn"
            :class="loan_type == 'applications' ? 'btn-default' : 'btn-outline-default'"><i
              class="fas fa-file-pen"></i></button>
          <button @click.prevent="loan_type = 'disbursements'" class="mx-2 round-button btn"
            :class="loan_type == 'disbursements' ? 'btn-default' : 'btn-outline-default'"><i
              class="fas fa-clipboard-check"></i></button>
          <button @click.prevent="loan_type = 'running'" class="mx-2 round-button btn"
            :class="loan_type == 'running' ? 'btn-default' : 'btn-outline-default'"><i
              class="fas fa-hourglass-half"></i></button>
          <button @click.prevent="loan_type = 'closed'" class="mx-2 round-button btn"
            :class="loan_type == 'closed' ? 'btn-default' : 'btn-outline-default'"><i
              class="fas fa-check-double"></i></button>
        </div>
        <div v-if="loan_type == 'applications'" class="container border-top mt-2 mb-4 pt-2 text-default text-center">
          <b>Loan Applications</b>
        </div>
        <div v-if="loan_type == 'disbursements'" class="container border-top mt-2 mb-4 pt-2 text-default text-center">
          <b>Approved Applications</b>
        </div>
        <div v-if="loan_type == 'running'" class="container border-top mt-2 mb-4 pt-2 text-default text-center">
          <b>Running Loans</b>
        </div>
        <div v-if="loan_type == 'closed'" class="container border-top mt-2 mb-4 pt-2 text-default text-center"><b>Closed
            Loans</b></div>
        <div class="card-body scrollable p-0">

          <div class="mx-3">
            <!-- Loan Applications Headers -->
            <div class="row px-0 align-items-center" style="font-size: 14px;">
              <div class="col-1"></div>
              <div class="col">Loan</div>
              <div class="col">PayBack</div>
              <div class="col">Date</div>
            </div>
            <div v-if="(loans[loan_type]).length > 0">
              <!-- Loan Application Content -->
              <div class="border rounded px-0 py-3 my-2 align-items-center" v-for="(loan, index) in loans[loan_type]"
                :key="index" v-if="index + 1 <= items" style="font-size: 13px;">
                <div class="row" @click.prevent="more_info = index">
                  <div class="col-1"><i class="fa fa-bank"></i></div>
                  <div class="col">{{ commas(loan.amount) }}/=</div>
                  <div class="col">{{ commas(loan.payback) }}/=</div>
                  <div class="col">{{ loan.applicationdate }}</div>
                </div>
                <div class="row mt-2" v-if="more_info == index">
                  <div class="col-1"></div>
                  <div class="col">
                    <small class="d-block">{{ loan.loan_type.name }}</small>
                    <small class="d-block text-primary">{{ loan.loan_type.period }} days</small>
                  </div>
                  <div class="col">
                    <small class="d-block">{{ commas(loan.repaymentplan) }}/=</small>
                    <small class="d-block text-primary">per {{ loan.repaymentstructure }}</small>
                  </div>
                  <div class="col">
                    <small class="d-block">You Get</small>
                    <small class="d-block text-primary">{{ commas(Number(loan.amount) - Number(loan.charges))
                      }}/=</small>
                  </div>
                </div>

              </div>
            </div>
            <div v-else>
              <div class="text-primary border rounded p-3 my-2 text-center">
                <img src="@/assets/images/no-data-found.png" alt="NO DATA FOUND" style="height: 200px;">
                <div class="text-default" style="font-size: 20px; margin-top: -40px;"><b>NO DATA FOUND</b></div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ViewLoans",
  data() {
    return {
      items: 5,
      loans: {
        applications: [],
        disbursements: [],
        running: [],
        closed: []
      },
      loan_type: 'applications', // chooses between applications, disbursements, running and, closed
      more_info: 0

    };
  },
  mounted() {
    this.fetchMyLoans();
  },
  computed: {

  },

  methods: {
    commas(x) { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); },
    async fetchMyLoans() {
      await axios.get('myloans').then((response) => {
        if (response.status == 200) {
          this.loans = response.data;
        }
      })
    }
  },
};
</script>