<template>
  <div id="app" style="width: 100dvw;">
    <AppHeader v-if="$route.path !== '/login' && $route.path !== '/register' && $route.path != '/forgot-password'"
      class="bg-white" />
    <div class="row">
      <div class="col-2 border"
        v-if="$route.path !== '/login' && $route.path !== '/register' && $route.path != '/forgot-password'">
        <SideBar class="d-none d-md-block" />
      </div>
      <div
        :class="{ 'col-12': ($route.path === '/login' || $route.path === '/register' || $route.path === '/forgot-password'), 'col-sm-12 col-md-10': !($route.path === '/login' || $route.path === '/register' || $route.path === '/forgot-password') }"
        class="all content">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import SideBar from "@/components/SideBar";
import AppHeader from "@/layout/AppHeader.vue"
import AppFooter from "@/layout/AppFooter.vue"
import axios from "axios";

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      handleUnauthorizedError();
    }
    return Promise.reject(error);
  }
);

function handleUnauthorizedError() {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  window.location.reload();
}

let updates = 1;      //! shows the number of updates made so far, this is to be updated as we upload system updates
// axios.defaults.baseURL = "http://127.0.0.1:8000/api";
axios.defaults.baseURL = 'https://admin.telekasoftware.com/api';

let user_system_updates = localStorage.getItem('user_system_updates') || 0;
if (updates != user_system_updates) {
  localStorage.setItem('updates_status', 0);
  localStorage.setItem('user_system_updates', updates);
}

const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
} else {
  axios.defaults.headers.common["Authorization"] = "";
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
}

export default {
  name: "app",
  components: {
    AppHeader,
    SideBar,
    AppFooter
  },

  data() {
    return {
      test: ''
    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style>
.round-button {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  width: 50px;
  /* Adjust size as needed */
  height: 50px;
  /* Adjust size as needed */
  border-radius: 50% !important;
  text-align: center;
}
</style>