import axios from "axios";

export const state = {
    memberships: []
};

export const getters = {
    memberships: state => state.memberships
};

export const mutations = {
    setMemberships(state, newValue) {
        state.memberships = newValue
    },
};

export const actions = {
    fetchMemberships({ commit }) {
        axios.get('/mymembership').then(res => {
            commit('setMemberships', res.data.fees)
        })
    },
};
