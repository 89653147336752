import axios from "axios";

export const state = {
    savings: []
};

export const getters = {
    savings: state => state.savings
};

export const mutations = {
    setSavings(state, newValue) {
        state.savings = newValue
    },
};

export const actions = {
    fetchSavings({ commit }) {
        axios.get('/mybalance').then(res => {
            commit('setSavings', res.data.savings)
        })
    },
};
