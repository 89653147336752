import axios from "axios";

export const state = {
    myloans: []
};

export const getters = {
    myloans: state => state.myloans
};

export const mutations = {
    setMyLoans(state, newValue) {
        state.myloans = newValue
    },
};

export const actions = {
    fetchMyLoans({ commit }) {
        axios.get('/myloans').then(res => {
            commit('setMyLoans', res.data.loans)
        })
    },
};
