<template>
    <div>
        <div v-if="isActive" class="spinner-border spinner-border-sm" role="status">
        </div>
        <div v-else>{{ defaultText }}</div>
    </div>
</template>

<script>
export default {
    props: {
        isActive: {
            type: Boolean,
            default: false
        },
        defaultText: {
            type: String,
            default: 'Submit'
        }
    }
}
</script>