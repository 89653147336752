import axios from "axios";

export const state = {
    loantypes: []
};

export const getters = {
    loantypes: state => state.loantypes
};

export const mutations = {
    setLoantypes(state, newValue) {
        state.loantypes = newValue
    },
};

export const actions = {
    fetchLoantypes({ commit }) {
        axios.get('/loantypes').then(res => {
            commit('setLoantypes', res.data.loantypes)
        })
    },
};
