<template>
  <div class="profile-page">
    <div class="text-center mt-3">
      <i class="d-md-block d-none text-white fa fa-user-circle-o" style="font-size: 200px;"></i>
      <i class="d-md-none d-block text-white fa fa-user-circle-o" style="font-size: 200px; padding-bottom:50px ;"></i>

    </div>
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </section>
    <div class="d-none d-md-block" style="margin-top: 100px; margin-left: 150px; margin-right: 150px;">
      <card shadow class="card-profile" no-body>
        <div class="px-4 mt-3">
          <div class="text-center">
            <h3 class="m-0">{{ userData.member.name }}</h3>
            <div class="h6 font-weight-300"><span class="font-weight-light">{{ userData.member.gender }} - {{
              Math.floor((new Date() - new Date(userData.member.date_of_birth)) /
                (1000 * 60 * 60 * 24 * 365.25)) }}</span></div>
            <div class="h6 font-weight-300"><i class="fa fa-map-marker mr-2"></i>{{ userData.member.address }}</div>
            <div class="h6"><i class="fa fa-envelope mr-2"></i>Email: {{ userData.member.email }}</div>
            <div class="h6"><i class="fa fa-phone mr-2"></i>Contact: {{ userData.member.telephone }}</div>
            <div class="text-primary h6 mt-4"><i>Member of <b>{{ userData.business }}</b> since <b>{{
              userData.member.date_of_joining }}</b></i></div>
          </div>
          <div class="my-3 border-top text-center">
            <div class="row justify-content-center">
              <div class="col-lg-9">
                <p><i>{{ quote[0].quote }}</i></p>
                <small class="text-primary">Quote by: {{ quote[0].author }}</small>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>

    <!-- For Phone -->
    <div class="d-block d-md-none" style="margin-top: 100px;">
      <card shadow class="card-profile" no-body>
        <div class="px-4 mt-3">
          <div class="text-center mt-3">
            <h4 class="mb-2">{{ userData.member.name }}</h4>
            <div class="h6 font-weight-300"><span class="font-weight-light">{{ userData.member.gender }} - {{
              Math.floor((new Date() - new Date(userData.member.date_of_birth)) /
                (1000 * 60 * 60 * 24 * 365.25)) }}</span></div>
            <div class="h6 font-weight-300"><i class="fa fa-map-marker mr-2"></i>{{ userData.member.address }}</div>
            <div class="h6"><i class="fa fa-envelope mr-2"></i>Email: {{ userData.member.email }}</div>
            <div class="h6"><i class="fa fa-phone mr-2"></i>Contact: {{ userData.member.telephone }}</div>
            <div class="text-primary h6 mt-4"><i>Member of <b>{{ userData.business }}</b> since <b>{{
              userData.member.date_of_joining }}</b></i></div>
          </div>
          <div class="my-3 border-top text-center">
            <div class="row justify-content-center">
              <div class="col-lg-9">
                <p><i>{{ quote[0].quote }}</i></p>
                <small class="text-primary">Quote by: {{ quote[0].author }}</small>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("user")),
      quote: [{
        quote: "The only way to do great work is to love what you do.",
        author: "Steve Jobs"
      }],
    }
  },
  mounted() {
    this.getQuote();
  },

  methods: {
    async getQuote() {
      const response = await fetch('https://api.api-ninjas.com/v1/quotes?category=inspirational', { headers: { 'X-Api-Key': '5gXxDzmPOzeCWEBD60Doig==zXJcGfgxetL9xX0D' } })
      if (response.status == 200) {
        this.quote = await response.json();
      }
    }
  }
};
</script>
<style scoped>
.section-profile-cover {
  position: absolute;
  /* Position the section absolutely */
  top: 0;
  /* Align to the top of the parent element */
  left: 0;
  /* Align to the left of the parent element */
  width: 100%;
  /* Full width to cover the background */
  height: 100%;
  /* Full height to cover the background */
  z-index: -1;
  /* Set a negative z-index to place it behind other elements */
  /* Optional: Add a lower z-index if other content needs to appear in front */
}
</style>