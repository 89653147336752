import axios from "axios";

export const state = {
    transactions: [],
};

export const getters = {
    transactions: (state) => state.transactions,
};

export const mutations = {
    setTransactions(state, newValue) {
        state.transactions = newValue;
    },
};

export const actions = {
    fetchTransactions({ commit }) {
        axios.get("/mytransactions").then((res) => {
            commit("setTransactions", res.data.transactions);
        });
    },
};
