<template>
  <header class="header-global">
    <!-- Off-canvas element -->
    <transition name="slide-fade">
      <div v-show="off_canvas" @click.stop class="offcanvas bg-white"
        style="position: absolute; height: 100vh; width:70vw; z-index:1;">
        <div class="text-center">
          <img src="@/assets/images/logo.png" alt="logo" style="height: 80px;">
          <div class="text"><b>Teleka</b></div>
        </div>
        <div class="container-xl">
          <hr class="my-3">
        </div>
        <div v-for="(item, index) in menuItems" :key="index" class="p-3">
          <router-link :to="item.link" @click.native="off_canvas = false"><span><i :class="item.icon"
                class="mx-3"></i>{{ item.name
              }}</span></router-link>
        </div>
        <div class="container-xl">
          <hr class="my-3">
        </div>
        <a href="/logout" class="text-danger p-3"><span><i class="fa fa-sign-out mx-3"></i><b>Logout</b></span></a>
      </div>
    </transition>
    <!-- End of Off-canvas element -->
    <div class="container my-3 mb-md-0 mt-md-2 navbar border-bottom bg-white">
      <i @click="toggleWithDelay" class="fa fa-bars btn btn-white btn-sm d-md-none d-block px-3"
        style="font-size: 30px"></i>
      <router-link class="mr-4 d-none d-md-block" to="/">
        <img src="@/assets/images/logo.png" alt="logo" style="height: 50px;">
      </router-link>

      <button slot="title" class="btn btn-primary" data-toggle="modal" data-target="#saving">
        <!-- <span v-if="!saving_hover"><i class="ni ni-money-coins"></i> Save</span> -->
        <span class="d-none d-md-block"><i class="ni ni-money-coins"></i> Make a Saving</span>
        <span class="d-block d-md-none"><i class="ni ni-money-coins"></i> Save</span>
      </button>
      <button @click.prevent="FindBalance" slot="title" class="btn btn-outline-primary" data-toggle="modal"
        data-target="#loan">
        <span class="d-none d-md-block"><i class="fa fa-bank"></i> Apply for Loan</span>
        <span class="d-block d-md-none"><i class="fa fa-bank"></i> Loan</span>
      </button>

      <li class="align-items-lg-center ml-lg-auto nav-item dropdown d-none d-lg-block ml-lg-4">
        <base-dropdown tag="li" class="nav-item">
          <button slot="title" class="dropdown-toggle btn btn-outline-dark">
            <span><i class="fa fa-user mr-2"></i>{{ userData.username }}</span>
          </button>
          <div class="dropdown-item border-bottom border-5 pb-2"><b>{{ userData.member.name }}</b></div>
          <router-link to="/profile" class="dropdown-item">Profile</router-link>
          <a href="/logout" class="dropdown-item text-danger"><b>Logout</b></a>
        </base-dropdown>
      </li>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="saving" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">

      <div class="modal-dialog modal-lg d-md-block d-none" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col-7">
                <form @submit.prevent="">
                  <div class="display-4 text-center border-bottom mb-2">Make a Saving</div>
                  <div class="">
                    <div class="m-0">Sender Information</div>
                    <div class="border p-2">
                      <div class="row">
                        <div class="col-6">
                          <label for="name" class="mb-0"><b>Name:</b></label>
                          <small class="d-block mb-2">
                            {{ userData.member.name }}
                          </small>
                        </div>
                        <div class="col-6">
                          <label for="email" class="mb-0"><b>Email:</b></label>
                          <small class="d-block mb-2">
                            {{ email }}
                          </small>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <label for="telephone" class="mb-0"><b>Telephone:</b></label>
                          <small class="d-block">
                            {{ contact }}
                          </small>
                        </div>
                        <div class="col-6">
                          <label for="address" class="mb-0"><b>Address:</b></label>
                          <small class="d-block">
                            {{ userData.member.address }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div>Receiver's Details</div>
                    <div class="border p-2">
                      <div class="row">
                        <div class="col-6">
                          <label for="name" class="mb-0"><b>Name:</b></label>
                          <small class="d-block">
                            {{ userData.business }}
                          </small>
                        </div>
                        <div class="col-6">
                          <label for="" class="mb-0"><b>Location:</b></label>
                          <small class="d-block">
                            {{ userData.location }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="p-2">
                      <label for="saving" class="mb-0"><b>Amount: (UGX)</b></label>
                      <small class="d-block mb-0 text-primary">{{ saving_package }}</small>
                      <input class="display-1 form-control mb-2 text-dark" v-model="saving" />
                      <div class="row">
                        <div class="col-7">
                          <label for="saving" class="mb-0"><b>Email:</b></label>
                          <input type="text" class="display-3 form-control text-dark" v-model="email">
                        </div>
                        <div class="col-5">
                          <label for="saving" class="mb-0"><b>Mobile Number:</b></label>
                          <input type="text" id="saving" class="display-3 form-control text-dark" v-model="contact">
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
              <div class="col-5">
                <div class="p-3 mt-5 shadow shadow-lg bg-white">
                  <div class="display-4 text-center border-bottom mb-2">Summary</div>
                  <div class="mb-3">
                    <div class="row">
                      <div class="col-6">Sender:</div>
                      <div class="col-6">{{ userData.member.name }}</div>
                      <div class="col-6">Receiver:</div>
                      <div class="col-6">{{ userData.business }}</div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-6">Mobile Number:</div>
                      <div class="col-6">{{ contact }}</div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-6">Amount:</div>
                      <div class="col-6">{{ commas(saving) }}/=</div>
                      <div class="col-6">
                        Service Fees: <i @mouseover="toolPinHover = true" @mouseleave="toolPinHover = false"
                          class="fa fa-info-circle"></i>
                        <small v-if="toolPinHover" class="d-block bg-white border rounded shadow shadow-lg p-2"
                          style="width: 250px; color: black; position: absolute; z-index: 10;">Used to run and maintain
                          the Teleka
                          System</small>
                      </div>
                      <div class="col-6">{{ 500 }}/=</div>
                      <div class="col-12 mt-3">
                        <v-select v-model="payment_method" :options="paymentmethods"
                          :reduce="payment_method => payment_method.id" label="name"
                          placeholder="Payment Method"></v-select>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-6" style="font-size: 25px">Total:</div>
                      <div class="col-6" style="font-size: 25px">{{ commas(Number(saving) + 500) }}/=</div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <span v-if="!email || !contact || !saving || !payment_method"
                      class="text-dark ml-3"><b>PAY</b></span>
                    <flutterwave-pay-button v-else :tx_ref="generateReference()" :amount="Number(saving) + 500"
                      payment_options="['card', 'mobilemoneyuganda', 'mobilemoneyzambia', 'mobilemoneyrwanda', 'mobilemoneyghana', 'mobilemoneytanzania', 'mobilemoneykenya']"
                      currency="UGX" :meta="{
                        consumer_id: '7898',
                        consumer_mac: 'kjs9s8ss7dd'
                      }" :customer="{ name: userData.member.name, phone_number: contact, email: email }"
                      :customizations="{ title: userData.business, description: 'Make a Saving', logo: 'logo.png' }"
                      :callback="handleFlutterwavePayment" :onclose="handleFlutterwaveClose" type="button"
                      class="btn btn-primary">
                      <div v-if="pay_loader" class="spinner-border spinner-border-sm" role="status">
                      </div>
                      <div v-else @click.prevent="pay_loader = true" style="padding: auto;">PAY</div>
                    </flutterwave-pay-button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <!-- For Phone -->
      <div class="modal-dialog modal-lg d-md-none d-block" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="">
                  <div class="display-4 text-center border-bottom mb-2">Make a Saving</div>
                  <!-- <div class="">
                    <div class="m-0">Sender Information</div>
                    <div class="border p-2">
                      <div class="row">
                        <div class="col-6">
                          <label for="name" class="mb-0"><b>Name:</b></label>
                          <small class="d-block mb-2">
                            {{ userData.member.name }}
                          </small>
                        </div>
                        <div class="col-6">
                          <label for="email" class="mb-0"><b>Email:</b></label>
                          <small class="d-block mb-2">
                            {{ email }}
                          </small>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <label for="telephone" class="mb-0"><b>Telephone:</b></label>
                          <small class="d-block">
                            {{ contact }}
                          </small>
                        </div>
                        <div class="col-6">
                          <label for="address" class="mb-0"><b>Address:</b></label>
                          <small class="d-block">
                            {{ userData.member.address }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="mt-3">
                    <div>Receiver's Details</div>
                    <div class="border p-2">
                      <div class="row">
                        <div class="col-6">
                          <label for="name" class="mb-0"><b>Name:</b></label>
                          <small class="d-block">
                            {{ userData.business }}
                          </small>
                        </div>
                        <div class="col-6">
                          <label for="" class="mb-0"><b>Location:</b></label>
                          <small class="d-block">
                            {{ userData.location }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="mt-2">
                    <div class="p-2">
                      <label for="saving" class="mb-0"><b>Amount: (UGX)</b></label>
                      <small class="d-block mb-0 text-primary">{{ saving_package }}</small>
                      <input class="display-1 form-control mb-2 text-dark" v-model="saving" />
                      <div class="row">
                        <div class="col-7">
                          <label for="saving" class="mb-0"><b>Email:</b></label>
                          <input type="text" class="display-3 form-control text-dark" v-model="email">
                        </div>
                        <div class="col-5">
                          <label for="saving" class="mb-0"><b>Mobile Number:</b></label>
                          <input type="text" id="saving" class="display-3 form-control text-dark" v-model="contact">
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
              <div class="col-12">
                <div class="p-3 mt-3 shadow shadow-lg bg-white">
                  <div class="display-4 text-center border-bottom mb-2">Summary</div>
                  <div class="mb-3">
                    <div class="row">
                      <div class="col-6">Sender:</div>
                      <div class="col-6">{{ userData.member.name }}</div>
                      <div class="col-6">Receiver:</div>
                      <div class="col-6">{{ userData.business }}</div>
                    </div>
                    <div class="row">
                      <div class="col-6">Mobile Number:</div>
                      <div class="col-6">{{ contact }}</div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-6">Amount:</div>
                      <div class="col-6">{{ commas(saving) }}/=</div>
                      <div class="col-6">
                        Service Fees: <i @mouseover="toolPinHover = true" @mouseleave="toolPinHover = false"
                          class="fa fa-info-circle"></i>
                        <small v-if="toolPinHover" class="d-block bg-white border rounded shadow shadow-lg p-2"
                          style="width: 250px; color: black; position: absolute; z-index: 10;">Used to run and maintain
                          the Teleka
                          System</small>
                      </div>
                      <div class="col-6">{{ 500 }}/=</div>

                      <div class="col-12 mt-3">
                        <select class="text-dark form-control" v-model="payment_method">
                          <option value="" selected disabled>Payment Method</option>
                          <option v-for="item in paymentmethods" :value="item.id">{{ item.name }}</option>
                        </select>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-6" style="font-size: 25px">Total:</div>
                      <div class="col-6" style="font-size: 25px">{{ commas(Number(saving) + 500) }}/=</div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <span v-if="!email || !contact || !saving || !payment_method"
                      class="text-dark ml-3"><b>PAY</b></span>
                    <flutterwave-pay-button v-else :tx_ref="generateReference()" :amount="Number(saving) + 500"
                      payment_options="['card', 'mobilemoneyuganda', 'mobilemoneyzambia', 'mobilemoneyrwanda', 'mobilemoneyghana', 'mobilemoneytanzania', 'mobilemoneykenya']"
                      currency="UGX" :meta="{
                        consumer_id: '7898',
                        consumer_mac: 'kjs9s8ss7dd'
                      }" :customer="{ name: userData.member.name, phone_number: contact, email: email }"
                      :customizations="{ title: userData.business, description: 'Make a Saving', logo: 'logo.png' }"
                      :callback="handleFlutterwavePayment" :onclose="handleFlutterwaveClose" type="button"
                      class="btn btn-primary">
                      <div v-if="pay_loader" class="spinner-border spinner-border-sm" role="status">
                      </div>
                      <div v-else @click.prevent="pay_loader = true" style="padding: auto;">PAY</div>
                    </flutterwave-pay-button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" id="loan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg d-md-block d-none" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col-7">
                <form @submit.prevent="">
                  <div class="display-4 text-center border-bottom mb-2">Loan Application</div>
                  <div class="">
                    <div class="m-0">Member Information</div>
                    <div class="border p-2">
                      <div class="row">
                        <div class="col-6">
                          <label for="name" class="mb-0"><b>Name:</b></label>
                          <small class="d-block mb-2">
                            {{ userData.member.name }}
                          </small>
                        </div>
                        <div class="col-6">
                          <label for="" class="mb-0"><b>Member ID:</b></label>
                          <small class="d-block mb-2">
                            {{ userData.member.member_id }}
                          </small>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <label for="telephone" class="mb-0"><b>Telephone:</b></label>
                          <small class="d-block">
                            {{ contact }}
                          </small>
                        </div>
                        <div class="col-6">
                          <label for="address" class="mb-0"><b>Address:</b></label>
                          <small class="d-block">
                            {{ userData.member.address }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div>Financial Status</div>
                    <div class="border p-2">
                      <div class="text-danger">{{ saveError }}</div>
                      <div class="row">
                        <div class="col-6">
                          <label for="name" class="mb-0"><b>Savings Balance:</b></label>
                          <div class="display-4">
                            {{ commas(savings) }}/=
                          </div>
                        </div>
                        <div class="col-6">
                          <label for="" class="mb-0"><b>Loan Limit:</b></label>
                          <div class="display-4">
                            {{ commas(Number(userData.settings.loanlimit) * Number(savings)) }}/=
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="p-2">
                      <div class="row">
                        <div class="col-6">
                          <label for="loan_type" class="mb-0"><b>Loan Package</b></label>
                          <v-select @input="FindPlan" class="mb-3" label="name" :options="loantypes" v-model="loan_type"
                            placeholder="Select Package" required></v-select>
                        </div>
                        <div class="col-6">
                          <label for="" class="mb-0"><b>Monthly Rate</b></label>
                          <div class="" style="font-size: 20px;">
                            <span style="font-weight: bold;">{{ loan_type.rate || 0 }}</span>% for <span
                              style="font-weight: bold;">{{ loan_type.period || 0 }}</span> days
                          </div>
                        </div>
                      </div>

                      <label for="saving" class="mb-0"><b>Loan Amount:</b></label>
                      <input @change="FindCharge"
                        @input="charge_loader = true; loan = loan > (Number(userData.settings.loanlimit) * Number(savings)) ? (Number(userData.settings.loanlimit) * Number(savings)) : loan;"
                        type="number" class="form-control" v-model="loan" style="font-weight: bold;">
                      <div>
                        <label class="mb-0 mt-2">Repayment Structure</label>
                        <v-select @input="FindPlan" :options="paymentstructures" required placeholder="Select an Option"
                          v-model="repaymentstructure" label="description"
                          :reduce="repaymentstructure => repaymentstructure.name"></v-select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-5">
                <div class="p-3 mt-5 shadow shadow-lg bg-white">
                  <div class="display-4 text-center border-bottom mb-2">Summary</div>
                  <div class="mb-3">
                    <div class="row">
                      <div class="col-4">Member:</div>
                      <div class="col-8">{{ userData.member.name }}</div>
                      <div class="col-4">ID:</div>
                      <div class="col-8">{{ userData.member.member_id }}</div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-4">Loan:</div>
                      <div class="col-8">{{ commas(loan) || 0 }}/=</div>
                      <div class="col-4">Interest:</div>
                      <div class="col-8"><i class="fa fa-plus-square text-success"></i> {{ commas(Number(loan) *
                        (Number(loan_type.rate)
                          || 0) * (Number(loan_type.period) || 0) / 30 / 100) }}/=</div>
                      <div class="col-4">Charges:</div>
                      <div class="col-8"><i class="fa fa-minus-square text-danger"></i> {{ commas(charges) || 0 }}/=
                        <span v-if="charge_loader" class="spinner-border spinner-border-sm" role="status">
                        </span>
                      </div>
                      <small v-if="loan && loan_type && repaymentstructure" class="mt-2 col-12 text-center text-primary"
                        style="font-weight: bold">Paying Plan: {{
                          commas(repaymentplan) || 0
                        }}/= per
                        {{ repaymentstructure }}</small>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-4 mb-0" style="font-size: 17px">Payback:</div>
                      <div class="col-8 mb-0" style="font-size: 20px">{{ commas(Number(loan) + Number(loan *
                        (loan_type.rate
                          || 0) * (loan_type.period || 0) / 30 / 100)) }}/=</div>

                      <div class="col-4" style="font-size: 17px">You get:</div>
                      <div class="col-8" style="font-size: 20px">{{ commas(Number(loan) - Number(charges)) || 0 }}/=
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button class="btn btn-primary" :disabled="saveError || !repaymentstructure || !loan || !loan_type"
                      @click.prevent="addLoanApplication">
                      <Spinner :is-active="apply_loader" :default-text="'Apply'" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- For Phone -->
      <div class="modal-dialog modal-lg d-md-none d-block" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="">
                  <div class="display-4 text-center border-bottom mb-2">Loan Application</div>
                  <div class="">
                    <div class="m-0">Member Information</div>
                    <div class="border p-2">
                      <div class="row">
                        <div class="col-6">
                          <label for="name" class="mb-0"><b>Name:</b></label>
                          <small class="d-block mb-2">
                            {{ userData.member.name }}
                          </small>
                        </div>
                        <div class="col-6">
                          <label for="" class="mb-0"><b>Member ID:</b></label>
                          <small class="d-block mb-2">
                            {{ userData.member.member_id }}
                          </small>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <label for="telephone" class="mb-0"><b>Telephone:</b></label>
                          <small class="d-block">
                            {{ contact }}
                          </small>
                        </div>
                        <div class="col-6">
                          <label for="address" class="mb-0"><b>Address:</b></label>
                          <small class="d-block">
                            {{ userData.member.address }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div>Financial Status</div>
                    <div class="border p-2">
                      <div class="text-danger">{{ saveError }}</div>
                      <div class="row">
                        <div class="col-6">
                          <label for="name" class="mb-0"><b>Savings Balance:</b></label>
                          <div class="display-4">
                            {{ commas(savings) }}/=
                          </div>
                        </div>
                        <div class="col-6">
                          <label for="" class="mb-0"><b>Loan Limit:</b></label>
                          <div class="display-4">
                            {{ commas(Number(userData.settings.loanlimit) * Number(savings)) }}/=
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="p-2">
                      <div class="row">
                        <div class="col-6">
                          <label for="loan_type" class="mb-0"><b>Loan Package</b></label>
                          <v-select @input="FindPlan" class="mb-3" label="name" :options="loantypes" v-model="loan_type"
                            placeholder="Select Package" required></v-select>
                        </div>
                        <div class="col-6">
                          <label for="" class="mb-0"><b>Monthly Rate</b></label>
                          <div class="" style="font-size: 20px;">
                            <span style="font-weight: bold;">{{ loan_type.rate || 0 }}</span>% for <span
                              style="font-weight: bold;">{{ loan_type.period || 0 }}</span> days
                          </div>
                        </div>
                      </div>

                      <label for="saving" class="mb-0"><b>Loan Amount:</b></label>
                      <input @change="FindCharge"
                        @input="charge_loader = true; loan = loan > (Number(userData.settings.loanlimit) * Number(savings)) ? (Number(userData.settings.loanlimit) * Number(savings)) : loan;"
                        type="number" class="form-control" v-model="loan" style="font-weight: bold;">
                      <div>
                        <label class="mb-0 mt-2">Repayment Structure</label>
                        <v-select @input="FindPlan" :options="paymentstructures" required placeholder="Select an Option"
                          v-model="repaymentstructure" label="description"
                          :reduce="repaymentstructure => repaymentstructure.name"></v-select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-12">
                <div class="p-3 mt-5 shadow shadow-lg bg-white">
                  <div class="display-4 text-center border-bottom mb-2">Summary</div>
                  <div class="mb-3">
                    <div class="row">
                      <div class="col-4">Member:</div>
                      <div class="col-8">{{ userData.member.name }}</div>
                      <div class="col-4">ID:</div>
                      <div class="col-8">{{ userData.member.member_id }}</div>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-4">Loan:</div>
                      <div class="col-8">{{ commas(loan) || 0 }}/=</div>
                      <div class="col-4">Interest:</div>
                      <div class="col-8"><i class="fa fa-plus-square text-success"></i> {{ commas(Number(loan) *
                        (Number(loan_type.rate)
                          || 0) * (Number(loan_type.period) || 0) / 30 / 100) }}/=</div>
                      <div class="col-4">Charges:</div>
                      <div class="col-8"><i class="fa fa-minus-square text-danger"></i> {{ commas(charges) || 0 }}/=
                        <span v-if="charge_loader" class="spinner-border spinner-border-sm" role="status">
                        </span>
                      </div>
                      <small v-if="loan && loan_type && repaymentstructure" class="mt-2 col-12 text-center text-primary"
                        style="font-weight: bold">Paying Plan: {{
                          commas(repaymentplan) || 0
                        }}/= per
                        {{ repaymentstructure }}</small>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-4 mb-0" style="font-size: 17px">Payback:</div>
                      <div class="col-8 mb-0" style="font-size: 20px">{{ commas(Number(loan) + Number(loan *
                        (loan_type.rate
                          || 0) * (loan_type.period || 0) / 30 / 100)) }}/=</div>

                      <div class="col-4" style="font-size: 17px">You get:</div>
                      <div class="col-8" style="font-size: 20px">{{ commas(Number(loan) - Number(charges)) || 0 }}/=
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button class="btn btn-primary" :disabled="saveError || !repaymentstructure || !loan || !loan_type"
                      @click.prevent="addLoanApplication">
                      <Spinner :is-active="apply_loader" :default-text="'Apply'" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Off Canvas -->

  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import Spinner from "@/components/Spinner";
import { FlutterwavePayButton } from "flutterwave-vue-v3";
import router from "@/router";
import axios from "axios";
import { loantypeComputed, loantypeMethods, savingComputed, savingMethods, paymentMethodComputed, paymentMethodMethods } from '@/state/helpers';


export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
    Spinner,
    FlutterwavePayButton
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("user")),
      pay_loader: false,
      saving: '',
      saving_package: '',
      payment_method: '',
      contact: '',
      email: '',
      transaction_loader: true,
      toolPinHover: false,

      loan: '',
      loan_type: '',
      charges: [],
      chargesError: '',
      charge_loader: false,
      saveError: '',
      apply_loader: false,
      repaymentplan: 0,
      repaymentstructure: '',
      paymentstructures: [
        { name: 'month', description: 'Monthly' },
        { name: 'week', description: 'Weekly' },
        { name: 'day', description: 'Daily' }
      ],
      application_date: '',
      interest: 0,
      payback: 0,
      loan_period: 0,

      off_canvas: false,
      menuItems: [
        {
          name: "Dashboard",
          icon: "fa fa-th-large",
          link: "/",
        },
        {
          name: "Subscriptions",
          icon: "fa fa-credit-card",
          link: "/membership",
        },
        {
          name: "Savings",
          icon: "fa fa-money",
          link: "/savings",
        },
        {
          name: "Withdraws",
          icon: "fa fa-arrow-circle-down",
          link: "/withdraws",
        },
        {
          name: "Shares",
          icon: "fa fa-line-chart",
          link: "/shares",
        },
        {
          name: "Loans",
          icon: "fa fa-university",
          link: "/loans",
        },
        {
          name: "Profile",
          icon: "fa fa-user",
          link: "/profile",
        },
      ]
    };
  },

  computed: {
    ...savingComputed,
    ...loantypeComputed,
    ...paymentMethodComputed
  },

  mounted() {

  },

  created() {
    this.contact = this.userData.member.telephone;
    this.email = this.userData.member.email;
    this.FindPackage();
    this.fetchSavings();
    this.fetchLoantypes();
    this.fetchPaymentMethods();
    document.addEventListener('click', this.handleClickOutside);
  },

  methods: {
    ...savingMethods,
    ...loantypeMethods,
    ...paymentMethodMethods,
    commas(x) { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); },

    toggleWithDelay() {
      // Add a delay of 500ms (0.5 seconds) before toggling off_canvas
      setTimeout(() => {
        this.off_canvas = !this.off_canvas;
      }, 100); // Adjust delay time as needed
    },


    handleClickOutside(event) {
      const offcanvasElement = this.$el.querySelector('.offcanvas');
      // If the click target is not within the off-canvas, close it
      if (offcanvasElement && !offcanvasElement.contains(event.target)) {
        if (this.off_canvas) {
          this.off_canvas = false;
        }

      }
    },

    generateReference() {
      let date = new Date();
      return date.getTime().toString();
    },

    async FindPackage() {
      let formData = new FormData();
      formData.append("member", this.userData.member.member_id);
      let url = "/findsavingpackage";
      await axios.post(url, formData).then((response) => {
        if (response.status == 200) {
          this.saving_package = response.data.packages[0].name
          this.saving = response.data.packages[0].amount;
        }
      });
    },

    async FindCharge() {
      this.FindPlan();
      let formData = new FormData();
      formData.append("member", this.userData.member.member_id);
      formData.append("amount", this.loan);
      formData.append('loan_limit', this.savings * this.userData.settings.loanlimit)
      let url = "/findloancharges";
      await axios.post(url, formData).then((response) => {
        if (response.status == 200) {
          this.charges = response.data.charges;
          this.chargesError = response.data.error;
        }
      });
      this.charge_loader = false
    },

    async handleFlutterwavePayment(response) {
      let formData = new FormData();
      formData.append("member", this.userData.member.member_id);
      formData.append("amount", this.saving);
      formData.append("date_of_payment", new Date().toISOString().split('T')[0]);
      formData.append("reference", response.tx_ref);
      formData.append("mode_of_payment", this.payment_method);
      let url = "/addsaving";
      await axios.post(url, formData).then((response) => {
        if (response.status == 200) {
          this.pay_loader = false;
          this.saving = '';
          this.contact = '';
          this.$toast.success("Saving successfully made");
        }
      });
    },

    handleFlutterwaveClose() {
      this.pay_loader = false;
      $('#saving').modal('hide');
    },
    // toggleMenu(event) {
    //   event.currentTarget.nextElementSibling.classList.toggle("mm-show");
    // },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },

    async FindBalance() {
      this.saveError = "";
      let url = `/savingsbalance/${this.userData.member.member_id}`;
      await axios.get(url).then((response) => {
        this.saveError = response.data.error;
      });
    },

    FindPlan() {
      this.interest = this.loan * (this.loan_type.rate || 0) * (this.loan_type.period || 0) / 30 / 100;
      this.payback = Number(this.loan) + Number(this.interest);
      this.loan_period = this.loan_type.period || 0;

      if (this.repaymentstructure == 'month') {
        this.repaymentplan = Math.ceil(this.payback / Number(this.loan_period / 30));
      }
      else if (this.repaymentstructure == 'week') {
        this.repaymentplan = Math.ceil(this.payback / Number(this.loan_period / 7));
      }
      else if (this.repaymentstructure == 'day') {
        this.repaymentplan = Math.ceil(this.payback / this.loan_period);
      }
    },

    async addLoanApplication() {
      this.loan_loader = true;
      this.saveError = "Saving Loan Application";
      this.interest = this.loan * (this.loan_type.rate || 0) * (this.loan_type.period || 0) / 30 / 100;
      this.payback = Number(this.loan) + Number(this.interest);
      this.application_date = new Date().toISOString().split('T')[0];

      let formData = new FormData();
      formData.append("member", this.userData.member.member_id);
      formData.append("loan_type", this.loan_type.id);
      formData.append("amount", this.loan);
      formData.append("charges", this.charges);
      formData.append("interest", this.interest);
      formData.append("payback", this.payback);
      formData.append("repaymentstructure", this.repaymentstructure);
      formData.append("repaymentplan", this.repaymentplan);
      formData.append("applicationdate", this.application_date);

      let url = "/addloanapplication";
      await axios.post(url, formData).then((response) => {
        if (response.status == 200) {
          this.member = "";
          this.loan = "";
          this.savingbalance = "";
          this.charges = "";
          this.$toast.info("New Loan Application Added");
          this.loan_loader = false;
          this.saveError = "";
          $('#loan').modal('hide');
        }
      });
    },
  },
  mounted() {

  }
};
</script>

<style>
.modal {
  background-color: rgba(0, 0, 0, 0.6);
  /* Adjust the opacity here */
}

/* Transition classes for the offcanvas */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-fade-enter {
  transform: translateX(-100%);
  /* Start offscreen */
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-100%);
  /* Leave offscreen */
  opacity: 0;
}
</style>
