<template>
  <div class="relative-position">
    <div class="d-md-block d-none p-3">
      <div>Shares</div>
      <div class="card shadow my-4">
        <div class="card-body scrollable p-0">
          <div class="d-flex align-items-center p-3">
            <h4 class="mt-3 mb-4">Shares</h4>
            <select v-model="items" id="transactions" placeholder="Show" class="ml-2 form-control form-control-sm"
              style="width: 60px;">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="80">80</option>
            </select>
          </div>
          <div class="mx-4">
            <div class="row px-3 align-items-center">
              <div class="col-1"></div>
              <div class="col"><b>Shares</b></div>
              <div class="col"><b>Amount</b></div>
              <div class="col"><b>Payment Method</b></div>
              <div class="col"><b>Date</b></div>
            </div>
            <div v-if="myshares.length > 0">
              <div class="row border rounded p-3 my-2 align-items-center" v-for="(share, index) in myshares"
                :key="index" v-if="index + 1 <= items">
                <div class="col-1"><i class="fa fa-line-chart"></i></div>
                <div class="col">{{ share.share }}</div>
                <div class="col">{{ commas(share.amount) }}/=</div>
                <div class="col">{{ share.mode_of_payment.name }}</div>
                <div class="col">{{ share.date_of_payment }}</div>
              </div>
            </div>

            <div v-else>
              <div class="text-primary border rounded p-3 my-2 text-center">
                <img src="@/assets/images/no-data-found.png" alt="NO DATA FOUND" style="height: 200px;">
                <div class="text-default" style="font-size: 20px; margin-top: -40px;"><b>NO DATA FOUND</b></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Phone -->
    <div class="d-block d-md-none p-3">
      <div>Shares</div>
      <div class="card shadow my-4">
        <div class="card-body scrollable p-0">
          <div class="d-flex align-items-center p-3">
            <h6 class="mt-3 mb-4">Shares</h6>
            <select v-model="items" id="transactions" placeholder="Show" class="ml-2 form-control form-control-sm"
              style="width: 60px;">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="80">80</option>
            </select>
          </div>
          <div style="margin-right: 18px; margin-left: 18px;">
            <div class="row align-items-center" style="font-size: 14px;">
              <div class="col-1"></div>
              <div class="col"><b>Shares</b></div>
              <div class="col"><b>Amount</b></div>
              <div class="col"><b>Date</b></div>
            </div>
            <div v-if="myshares.length > 0">
              <div class="row border rounded py-2 my-2 align-items-center" v-for="(share, index) in myshares"
                :key="index" v-if="index + 1 <= items" style="font-size: 13px;">
                <div class="col-1"><i class="fa fa-line-chart"></i></div>
                <div class="col">{{ share.share }}</div>
                <div class="col">{{ commas(share.amount) }}/= <small class="d-block text-primary">{{share.mode_of_payment.name}}</small></div>
                <div class="col">{{ share.date_of_payment }}</div>
              </div>
            </div>

            <div v-else>
              <div class="text-primary border rounded p-3 my-2 text-center">
                <img src="@/assets/images/no-data-found.png" alt="NO DATA FOUND" style="height: 200px;">
                <div class="text-default" style="font-size: 20px; margin-top: -40px;"><b>NO DATA FOUND</b></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { myShareComputed, myShareMethods } from "@/state/helpers";

export default {
  name: "ViewShares",
  data() {
    return {
      items: 5,
    };
  },
  mounted() {
    this.fetchMyShares();
  },
  computed: {
    ...myShareComputed,
  },

  methods: {
    ...myShareMethods,
    commas(x) { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); },
  },
};
</script>