import axios from "axios";

export const state = {
    mysavings: []
};

export const getters = {
    mysavings: state => state.mysavings
};

export const mutations = {
    setMySavings(state, newValue) {
        state.mysavings = newValue
    },
};

export const actions = {
    fetchMySavings({ commit }) {
        axios.get('/mysavings').then(res => {
            commit('setMySavings', res.data.savings)
        })
    },
};
