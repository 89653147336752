<template>
  <div class="relative-position">
    <div class="p-3 d-none d-md-block">
      <div>Dashboard</div>
      <div class="card shadow my-4 scrollable">
        <div class="card-body">
          <h2 class="border-bottom">Balances</h2>
          <div class="row">
            <div class="col">
              <div class="card m-2 bg-dark text-white">
                <div class="card-body">
                  <b>Savings</b>
                  <h3 class="text-white">UGX. {{ commas(savings) }}</h3>
                </div>

              </div>
            </div>
            <div class="col">
              <div class="card m-2 bg-primary text-white">
                <div class="card-body">
                  <b>Shares: {{ number_of_shares }}</b>
                  <h3 class="text-white">UGX. {{ commas(shares) }}</h3>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card m-2 bg-danger text-white">
                <div class="card-body">
                  <b>Loans</b>
                  <h3 class="text-white">UGX. {{ commas(loans) }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <h4 class="mt-3 mb-4 border-bottom">Your Transactions</h4>
            <select v-model="items" id="transactions" placeholder="Show" class="ml-2 form-control" style="width: 80px;">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="80">80</option>
            </select>
          </div>
          <div class="mx-3">
            <center>
              <div v-if="!transactions" class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </center>
            <div class="row rounded p-2 align-items-center" :class="{ border: transactions }"
              v-for="(transaction, index) in transactions" :key="index" v-if="index + 1 <= items">

              <div class="col-2">
                <i v-if="transaction.title == 'Shares'" class="fa fa-line-chart"></i>
                <i v-if="transaction.title == 'Savings'" class="fa fa-money"></i>
                <i v-if="transaction.title == 'Dividend'" class="fa fa-file"></i>
                <i v-if="transaction.title == 'Interest'" class="fa fa-signal"></i>
                <i v-if="transaction.title == 'Withdraws'" class="fa fa-arrow-circle-down"></i>

              </div>
              {{ transactions[i - 1] }}
              <div class="col-6">
                <div><b>{{ transaction.title }}</b></div>
                <small>{{ transaction.action }}</small>
              </div>
              <div class="col-4">{{ transaction.date_of_payment }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- For Phone -->
    <div class="p-3 d-block d-md-none">
      <div>Dashboard</div>
      <div class="card shadow my-4 scrollable">
        <div class="card-body">
          <h4 class="border-bottom">Balances</h4>
          <div class="row">
            <div class="col-12">
              <div class="card m-2 bg-dark text-white">
                <div class="card-body p-3">
                  <b>Savings</b>
                  <h5 class="text-white">UGX. {{ commas(savings) }}</h5>
                </div>

              </div>
            </div>
            <div class="col-12">
              <div class="card m-2 bg-primary text-white">
                <div class="card-body p-3">
                  <b>Shares: {{ number_of_shares }}</b>
                  <h5 class="text-white">UGX. {{ commas(shares) }}</h5>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card m-2 bg-danger text-white">
                <div class="card-body p-3">
                  <b>Loans</b>
                  <h5 class="text-white">UGX. {{ commas(loans) }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <h6 class="mt-3 mb-4 border-bottom">Your Transactions</h6>
            <select v-model="items" id="transactions" placeholder="Show" class="ml-2 form-control form-control-sm" style="width: 55px;">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="80">80</option>
            </select>
          </div>
          <div class="mx-3">
            <center>
              <div v-if="!transactions" class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </center>
            <div class="row rounded p-2 align-items-center" :class="{ border: transactions }"
              v-for="(transaction, index) in transactions" :key="index" v-if="index + 1 <= items">

              <div class="col text-center">
                <i v-if="transaction.title == 'Shares'" class="fa fa-line-chart"></i>
                <i v-if="transaction.title == 'Savings'" class="fa fa-money"></i>
                <i v-if="transaction.title == 'Dividend'" class="fa fa-file"></i>
                <i v-if="transaction.title == 'Interest'" class="fa fa-signal"></i>
                <i v-if="transaction.title == 'Withdraws'" class="fa fa-arrow-circle-down"></i>

                <small class="d-block">{{ transaction.date_of_payment }}</small>

              </div>
              {{ transactions[i - 1] }}
              <div class="col-7 text-center">
                <div><b>{{ transaction.title }}</b></div>
                <small>{{ transaction.action }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { transactionComputed, transactionMethods, savingComputed, savingMethods } from '@/state/helpers';

export default {
  name: "home",
  data() {
    return {
      items: 5,
      saving: '5000',
      shares: 0,
      number_of_shares: 0,
      loans: 0,
    }
  },

  computed: {
    ...transactionComputed,
    ...savingComputed
  },

  mounted() {
    this.dashboard();
    this.fetchTransactions();
    this.fetchSavings()
  },

  methods: {
    ...transactionMethods,
    ...savingMethods,
    commas(x) { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); },
    async dashboard() {
      try {
        const response = await axios.get('/dashboard');
        // this.savings = response.data.savings;
        this.shares = response.data.shares;
        this.number_of_shares = response.data.number_of_shares
        this.loans = response.data.loans;
        this.number_of_shares = response.data.number_of_shares;
      } catch (error) {
        console.log(error);
      }
    },

    async getTransactions() {
      try {
        const response = await axios.get('/mytransactions');
        this.transactions = response.data.transactions;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
