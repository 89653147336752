<template>
  <div class="mt-4">
    <div v-for="(item, index) in menuItems" :key="index" class="p-3">
      <router-link :to="item.link"><span><i :class="item.icon" class="mx-3"></i>{{ item.name
          }}</span></router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("user")),
      menuItems: [
        {
          name: "Dashboard",
          icon: "fa fa-th-large",
          link: "/",
        },
        {
          name: "Subscriptions",
          icon: "fa fa-credit-card",
          link: "/membership",
        },
        {
          name: "Savings",
          icon: "fa fa-money",
          link: "/savings",
        },
        {
          name: "Withdraws",
          icon: "fa fa-arrow-circle-down",
          link: "/withdraws",
        },
        {
          name: "Shares",
          icon: "fa fa-line-chart",
          link: "/shares",
        },
        {
          name: "Loans",
          icon: "fa fa-university",
          link: "/loans",
        },
        {
          name: "Profile",
          icon: "fa fa-user",
          link: "/profile",
        },
      ]
    };
  },
};
</script>