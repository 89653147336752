import { mapState, mapActions } from 'vuex'

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader
  })
}

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword'])

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue'])

export const transactionComputed = { ...mapState('transactions', {transactions: (state) => state.transactions })}
export const transactionMethods = mapActions('transactions', ['fetchTransactions'])

export const savingComputed = { ...mapState('savings', {savings: (state) => state.savings })}
export const savingMethods = mapActions('savings', ['fetchSavings'])

export const loantypeComputed = { ...mapState('loantypes', {loantypes: (state) => state.loantypes })}
export const loantypeMethods = mapActions('loantypes', ['fetchLoantypes'])

export const paymentMethodComputed = { ...mapState('paymentmethods', {paymentmethods: (state) => state.paymentmethods })}
export const paymentMethodMethods = mapActions('paymentmethods', ['fetchPaymentMethods'])

export const membershipComputed = { ...mapState('memberships', {memberships: (state) => state.memberships })}
export const membershipMethods = mapActions('memberships', ['fetchMemberships'])

export const mySavingComputed = { ...mapState('mysavings', {mysavings: (state) => state.mysavings })}
export const mySavingMethods = mapActions('mysavings', ['fetchMySavings'])

export const myWithdrawComputed = { ...mapState('mywithdraws', {mywithdraws: (state) => state.mywithdraws })}
export const myWithdrawMethods = mapActions('mywithdraws', ['fetchMyWithdraws'])

export const myShareComputed = { ...mapState('myshares', {myshares: (state) => state.myshares })}
export const myShareMethods = mapActions('myshares', ['fetchMyShares'])

export const myLoanComputed = { ...mapState('myloans', {myloans: (state) => state.myloans })}
export const myLoanMethods = mapActions('myloans', ['fetchMyLoans'])