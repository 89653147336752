<template>
  <footer class="footer py-3">
    <div class="row text-center">
      <div class="col-sm-4">{{ new Date().getFullYear() }} © Teleka.</div>
      <div class="col-sm-4">License Expires on <span class="text-primary">{{ expiredate }} </span> | <span
          class="text-danger"> {{ days }}</span></div>
      <div class="col-sm-4">
        <div class="text-sm-end d-none d-sm-block">A Product of <a href="https://www.mta.co.ug/" target="_blank">MTA
            Computers</a></div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      expiredate: localStorage.getItem("license"),
    };
  },
  computed: {
    days() {
      const today = new Date()
      const expires = new Date(this.expiredate)
      const difference = expires.getTime() - today.getTime()
      const left = Math.floor(difference / (1000 * 60 * 60 * 24))
      return left + ' days'
    },
  },
};
</script>