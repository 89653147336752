import Vue from "vue";
import App from "./App.vue";
import Argon from "./plugins/argon-kit";
import "./registerServiceWorker";
import router from "@/router/index";
import store from "@/state/store";
import VueLazyLoad from "vue-lazyload";
import Flutterwave from "flutterwave-vue-v3";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import '@fortawesome/fontawesome-free/css/all.css';


Vue.config.productionTip = false;
Vue.component("v-select", vSelect);
Vue.use(VueLazyLoad);
Vue.use(Flutterwave, {
    publicKey: "FLWPUBK-0465615976f3eb2aff5328fbb039f21e-X",
});
Vue.use(Argon);
Vue.use(Toast);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
