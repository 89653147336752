import axios from "axios";

export const state = {
    paymentmethods: []
};

export const getters = {
    paymentmethods: state => state.paymentmethods
};

export const mutations = {
    setPaymentMethods(state, newValue) {
        state.paymentmethods = newValue
    },
};

export const actions = {
    fetchPaymentMethods({ commit }) {
        axios.get('/methods').then(res => {
            commit('setPaymentMethods', res.data.methods)
        })
    },
};
