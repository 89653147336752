<template>
  <div class="page-with-bg">
    <div class="d-md-block d-none">
      <div class="row" style="background-color: whitesmoke;">
        <div class="col-5 d-flex align-items-center px-5">
          <form @submit.prevent="login" class="mx-5 w-100">
            <div class="text-dark roboto-bold h2 mb-0">Welcome to Teleka,</div>
            <div class="text-dark roboto-regular h4 mb-5">Please login...</div>
            <small v-if="error" class="d-block text-white bg-danger p-2 rounded mb-2"><b>{{ error }}</b></small>
            <base-input alternative class="mb-3" style="box-shadow: 0 0 50px rgba(0, 0, 0, 0.2)" placeholder="Username"
              addon-left-icon="ni ni-single-02" v-model="username">
            </base-input>
            <br>
            <div>
              <div class="input-group mb-3" style="box-shadow: 0 0 50px rgba(0, 0, 0, 0.2)">
                <div class="input-group-prepend">
                  <span class="bg-white d-flex align-items-center pl-2 pr-3"><i class="ni ni-lock-circle-open"
                      style="color: #b2beb5"></i>
                  </span>
                </div>
                <input :type="passwordVisible ? 'text' : 'password'" class="form-control border-0"
                  placeholder="Password" aria-label="User input" v-model="password" />
                <div class="input-group-append">
                  <span @click.prevent="togglePassword" class="bg-white d-flex align-items-center px-3"><i
                      :class="passwordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                  </span>
                </div>
              </div>
            </div>
            <!-- <base-checkbox> Remember me </base-checkbox> -->
            <div class="text-center">
              <button type="submit" class="btn btn-primary my-3" :disabled="!username || !password">
                <Spinner :is-active="login_loader" :default-text="'Sign In'" />
              </button>
            </div>
            <div class="text-default text-center" style="font-size: 10px">
              By logging in, you've agreed to our <a href="#">Terms & Conditions.</a>
            </div>
            <div class="d-flex justify-content-between mt-3">
              <small class="d-block"><a href="/forgot-password">Forgot Password</a></small>
              <small class="d-block">Don't have an account? <a href="/register" class="">Create</a></small>
            </div>
          </form>
        </div>
        <div class="col-7 text-center p-0">
          <img src="@/assets/images/login_page.png" alt="" style="height: 100dvh;">
        </div>
      </div>
    </div>

    <!-- For Phone -->
    <div class="d-md-none d-block">
      <div class="d-flex align-items-center justify-content-center" style="padding-top: 25%;">
        <form @submit.prevent="login" class="bg-white p-3 rounded rounded-5">
          <center class="mb-3"><img src="logo.png" alt="" style="height: 80px;"></center>
          <div class="roboto-bold h4 mb-0 text-primary text-center"><b>Welcome to Teleka.</b></div>
          <div class="roboto-regular h6 mb-4 text-primary text-center">Please login...</div>
          <small v-if="error" class="d-block text-white bg-danger p-2 rounded mb-2"><b>{{ error }}</b></small>
          <base-input alternative class="mb-3" style="box-shadow: 0 0 50px rgba(0, 0, 0, 0.2)" placeholder="Username"
            addon-left-icon="ni ni-single-02" v-model="username">
          </base-input>
          <br>
          <div>
            <div class="input-group mb-3" style="box-shadow: 0 0 50px rgba(0, 0, 0, 0.2)">
              <div class="input-group-prepend">
                <span class="bg-white d-flex align-items-center pl-2 pr-3"><i class="ni ni-lock-circle-open"
                    style="color: #b2beb5"></i>
                </span>
              </div>
              <input :type="passwordVisible ? 'text' : 'password'" class="form-control border-0" placeholder="Password"
                aria-label="User input" v-model="password" />
              <div class="input-group-append">
                <span @click.prevent="togglePassword" class="bg-white d-flex align-items-center px-3"><i
                    :class="passwordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                </span>
              </div>
            </div>
          </div>

          <div class="text-center">
            <button type="submit" class="btn btn-primary my-3" :disabled="!username || !password">
              <Spinner :is-active="login_loader" :default-text="'Sign In'" />
            </button>
          </div>
          <div class="text-default text-center" style="font-size: 10px">
            By logging in, you've agreed to our <a href="#" class="text-link">Terms & Conditions.</a>
          </div>
          <div class="text-center mt-3">
            <small class="d-block"><a href="/forgot-password" class="text-link">Forgot Password</a></small>
            <small class="d-block text-default">Don't have an account? <a href="/register"
                class="text-link">Create</a></small>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Spinner from '@/components/Spinner.vue'

export default {
  data() {
    return {
      username: '',
      password: '',
      login_loader: false,
      passwordVisible: false,
    }
  },
  components: {
    Spinner
  },

  methods: {
    togglePassword() {
      this.passwordVisible = !this.passwordVisible
    },
    async login() {
      this.login_loader = true;
      let formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);
      let url = "/login";
      await axios.post(url, formData).then((response) => {
        if (response.status == 200) {
          let userData = response.data.user;
          let token = response.data.token;
          let financialyear = response.data.financialyear;
          let license = response.data.license;
          let branches = response.data.branches;
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          localStorage.setItem("user", JSON.stringify(userData));
          localStorage.setItem("token", token);
          localStorage.setItem("financialyear", financialyear);
          localStorage.setItem("license", license);
          localStorage.setItem("branches", JSON.stringify(branches));

          // checks for a valid license
          const end_date = new Date(localStorage.getItem('license'))
          const today = new Date()
          if (end_date < today) {
            localStorage.setItem('active_license', 0)
          } else {
            localStorage.setItem('active_license', 1)
          }
          // end

          if (branches.length > 1) {
            this.$router.push('/choose_branch')
          } else {
            if (end_date < today) {
              this.$router.push('/subscriptions')
              this.$toast.error('Your License has expired!')

              this.$router.beforeEach((to, from, next) => {
                next(false);
              });
            } else {
              this.$router.push(
                this.$route.query.redirectFrom || { name: "default" }
              );
            }
          }
        } else {
          // this.error = response.data.message;
          this.error = "Invalid username or password";
          this.login_loader = false;
        }
      });
    },
  }
};
</script>

<style>
.text-link {
  color: #89CFF0;
}
.page-with-bg {
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}
</style>
