import axios from "axios";

export const state = {
    mywithdraws: []
};

export const getters = {
    mywithdraws: state => state.mywithdraws
};

export const mutations = {
    setMyWithdraws(state, newValue) {
        state.mywithdraws = newValue
    },
};

export const actions = {
    fetchMyWithdraws({ commit }) {
        axios.get('/mywithdraws').then(res => {
            commit('setMyWithdraws', res.data.withdraws)
        })
    },
};
