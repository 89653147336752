<template>
  <section class="section section-shaped section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-default" style="height: 100vh;">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="container pt-0">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 pt-lg-4" class="border-0">
            <template>
              <div class="text-muted text-center mb-3">
                <img src="@/assets/images/logo.png" alt="logo" style="height: 100px;">
                <br><br>
                <div>Forgot Password</div>
              </div>
              <div class="btn-wrapper text-center">

              </div>
            </template>
            <template>

              <!-- Send Token -->
              <form @submit.prevent="recoveryEmail" v-if="page == 'get_token'">
                <base-input alternative class="mb-3" placeholder="Recovery Email" addon-left-icon="ni ni-email-83"
                  v-model="email" type="email">
                </base-input>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary my-4">
                    <Spinner :is-active="token_loader" :default-text="'Send Code'" />
                  </button>
                </div>
              </form>

              <!-- Enter token to reset password -->
              <form @submit.prevent="verifyToken" v-if="page == 'reset_password'">
                <div class="text-center">Enter OTP</div>
                <small class="d-block text-default text-center">Check your email for reset password token.</small>
                <small class="d-block text-default text-center"><i>Didn't receive email. <span style="cursor: pointer;"
                      class="text-primary" @click.prevent="recoveryEmail"><b>Resend</b></span></i></small>
                <div class="row mt-3">
                  <input ref="token1" v-model="token1" @input="checkDigit('token1', 'token2')" @paste="handlePaste"
                    class="shadow shadow-sm mx-1 text-center col form-control form-control-lg" style="font-size: 30px;">
                  <input ref="token2" v-model="token2" @input="checkDigit('token2', 'token3')"
                    class="shadow shadow-sm mx-1 text-center col form-control form-control-lg" style="font-size: 30px;">
                  <input ref="token3" v-model="token3" @input="checkDigit('token3', 'token4')"
                    class="shadow shadow-sm mx-1 text-center col form-control form-control-lg" style="font-size: 30px;">
                  <input ref="token4" v-model="token4" @input="checkDigit('token4', '')"
                    class="shadow shadow-sm mx-1 text-center col form-control form-control-lg" style="font-size: 30px;">
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary my-4">
                    <Spinner :is-active="reset_loader" :default-text="'Verify'" />
                  </button>
                </div>
              </form>

              <!-- Enter New Password -->
              <form @submit.prevent="changePassword" v-if="page == 'enter_new_password'">
                <div class="container row mt-3">
                  <label for="password1" class="mb-0">New Password</label>
                  <input id="password1" type="password" class="form-control mb-3" v-model=password1>

                  <label for="password2" class="mb-0">Confirm Password</label>
                  <input id="password2" type="password" class="form-control" v-model=password2>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary my-4">
                    <Spinner :is-active="password_loader" :default-text="'Change Password'" />
                  </button>
                </div>
              </form>
              <div class="row mt-3">
                <div class="col-6">
                  <a href="/login" class="text-primary">
                    <small>Back to Login</small>
                  </a>
                </div>
                <div class="col-6 text-right">
                  <a href="/register" class="text-primary">
                    <small>Create new account</small>
                  </a>
                </div>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import Spinner from '@/components/Spinner.vue'

export default {
  data() {
    return {
      email: '',
      token_loader: false,
      reset_loader: false,
      password: '',
      login_loader: false,
      passwordVisible: false,
      page: 'get_token', // page 1 - get_token, page 2 - reset_password, page 3 - enter_new_password
      token1: '',
      token2: '',
      token3: '',
      token4: '',

      password1: '',
      password2: '',
      password_loader: false,
    }
  },
  components: {
    Spinner
  },

  watch: {
    page(newValue) {
      if (newValue == 'reset_password') {
        this.$nextTick(() => {
          this.$refs.token1.focus();  // Focus on the input when div becomes visible
        });
      }
    },
  },

  methods: {
    togglePassword() {
      this.passwordVisible = !this.passwordVisible
    },

    checkDigit(current_model, next_model) {
      const regex = /^[0-9]*$/;
      let value = this[current_model];
      if (!regex.test(value)) {
        value = value.replace(/[^0-9]/g, '');
      }
      this[current_model] = value;

      if (this[current_model].length >= 1 && next_model) {
        this.$refs[next_model].focus();
      }
    },

    handlePaste(event) {
      // Prevent the default paste behavior
      event.preventDefault();

      // Get the pasted text from the clipboard
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');

      // Loop through the pasted text and assign each character to the corresponding token
      if (pastedText.length > 0) this.token1 = pastedText.charAt(0) || '';
      if (pastedText.length > 1) this.token2 = pastedText.charAt(1) || '';
      if (pastedText.length > 2) this.token3 = pastedText.charAt(2) || '';
      if (pastedText.length > 3) this.token4 = pastedText.charAt(3) || '';

      // Move focus to the next empty token input field
      if (this.token1 === '') this.$refs.token1.focus();
      else if (this.token2 === '') this.$refs.token2.focus();
      else if (this.token3 === '') this.$refs.token3.focus();
      else if (this.token4 === '') this.$refs.token4.focus();
    },

    async recoveryEmail() {
      this.token_loader = true
      const obj = {
        email: this.email
      }

      await axios.post('/reset-token', obj).then((response) => {
        if (response.data.code == 'success') {
          this.$toast.success('OTP sent. Check your email.');
          this.page = 'reset_password'
          this.token_loader = false
        } else {
          this.$toast.error(response.data.message);
          this.token_loader = false
        }

      });

    },

    async verifyToken() {
      this.reset_loader = true
      const obj = {
        email: this.email,
        token: String(this.token1) + String(this.token2) + String(this.token3) + String(this.token4),
      }
      try {
        const response = await axios.post('/verify-token', obj);
        if (response.data.code == 'success') {
          this.$toast.success('Token verified. Enter new password.');
          this.page = 'enter_new_password';
          this.reset_loader = false;
        }
        else {
          this.$toast.error(response.data.message);
          this.reset_loader = false;
        }
      } catch (error) {
        this.$toast.error('Error verifying token:');
        this.reset_loader = false;
      }
    },

    async changePassword() {
      this.password_loader = true
      const obj = {
        email: this.email,
        password: this.password1
      }

      try {
        const response = await axios.post('/change-password', obj);
        if (response.data.code == 'success') {
          this.$toast.success('Password changed successfully.');
          this.password_loader = false;
          this.$router.push('/login');
        }
        else {
          this.$toast.error(response.data.error);
        }
      } catch (error) {
        this.$toast.error('Error changing password:');
      }
    }
  }
};
</script>

<style></style>
