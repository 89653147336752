import store from "@/state/store";
import Landing from "@/views/Landing.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import Profile from "@/views/Profile.vue";
import ViewSubscriptions from "@/views/subscriptions/ViewSubscriptions.vue";
import ViewSavings from "@/views/savings/ViewSavings.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ViewWithdraws from "@/views/withdraws/ViewWithdraws.vue";
import ViewShares from "@/views/shares/ViewShares.vue";
import ViewLoans from "@/views/loans/ViewLoans.vue";

export default [
    {
        path: "/login",
        name: "login",
        components: {
            default: Login,
        },
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters["auth/loggedIn"]) {
                    next({ name: "default" });
                } else {
                    next();
                }
            },
        },
    },
    {
        path: "/register",
        name: "register",
        components: {
            default: Register,
        },
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters["auth/loggedIn"]) {
                    next({ name: "default" });
                } else {
                    next();
                }
            },
        },
    },
    {
        path: "/forgot-password",
        name: "forgotpassword",
        components: {
            default: ForgotPassword,
        },
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters["auth/loggedIn"]) {
                    next({ name: "default" });
                } else {
                    next();
                }
            },
        },
    },
    {
        path: "/logout",
        name: "logout",
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                localStorage.removeItem("branches");
                localStorage.removeItem("financialyear");
                localStorage.removeItem("license");
                localStorage.removeItem("active_license");
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push("/login")
                );
                next(
                    authRequiredOnPreviousRoute
                        ? { name: "default" }
                        : { ...routeFrom }
                );
            },
        },
    },
    {
        path: "/",
        name: "default",
        meta: {
            authRequired: true,
        },
        components: {
            default: Landing,
        },
    },
    {
        path: "/membership",
        name: "membership",
        components: {
            default: ViewSubscriptions,
        },
        meta: {
            authRequired: true,
        },
    },
    {
        path: "/savings",
        name: "savings",
        components: {
            default: ViewSavings,
        },
        meta: {
            authRequired: true,
        },
    },
    {
        path: "/withdraws",
        name: "withdraws",
        components: {
            default: ViewWithdraws,
        },
        meta: {
            authRequired: true,
        },
    },
    {
        path: "/shares",
        name: "shares",
        components: {
            default: ViewShares,
        },
        meta: {
            authRequired: true,
        },
    },
    {
        path: "/loans",
        name: "loans",
        components: {
            default: ViewLoans,
        },
        meta: {
            authRequired: true,
        },
    },
    {
        path: "/profile",
        name: "profile",
        components: {
            default: Profile,
        },
        meta: {
            authRequired: true,
        },
    },
];
